<template>
  <div class="put-in-middle">
    <div class="text-center">
      <CRow>
        <CCol>
          <h1><strong>404</strong> Page Not Found</h1>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol>
          <h2>
            <strong>{{ response }}</strong>
          </h2>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol>
          <h4>We couldn't find what you were looking for.</h4>
          <h4>Maybe check your link and see if it's correct?</h4>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol>
          <CLink class="btn btn-primary" to="dashboard"> Return Home </CLink>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
const RESPONSES = [
  'Oh No!',
  'Yikes!',
  'Great Scott!',
  'Oops!',
  'Crikey!',
  'Bazinga!',
  'Gadzooks!',
  'Zoinks!',
  'Ack!',
];

export default {
  name: 'NotFound',
  data() {
    return {};
  },
  computed: {
    response() {
      let random = Math.random() * RESPONSES.length;
      return RESPONSES[Math.floor(random)];
    },
  },
};
</script>
<style lang="scss">
.put-in-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65vh;
}
</style>
